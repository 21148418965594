/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

/*
font-family: 'Barlow Condensed', sans-serif;
font-family: 'Lato', sans-serif;
*/

* { margin: 0; padding: 0; }


body {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    color: #fff;
    overflow: hidden;
    @media (max-width: 767px) {
        font-size: 12px;
    }

    @media screen and (orientation:landscape)
    and (min-device-width: 320px)
    and (max-device-width: 1024px) {
        overflow: visible;
    }

    .btn {
        height: 3.7em;
        border-color: #fff !important;
        border-radius: 0;
        font-family: 'Lato', sans-serif;
        font-size: 1em;
        padding-left: 2.5em;
        padding-right: 2.5em;
        transition: 0.3s ease;
        &:hover {
            background-color: #00ACC4;
        }
        @media (max-width: 767px) {
            font-size: 1.2em;
        }
    }
    .btn-blue-outline {
        border-color: #00ACC4 !important;
        &:hover {
            background-color: #00ACC4;
        }
    }
    .btn-white {
        color: #3C3D3E !important;
        background-color: #fff !important;
        &:hover {
            color: #fff !important;
            background-color: #00ACC4 !important;
            border-color: #00ACC4 !important;
        }
    }
    p, li {
        font-size: 18px;
        @media (max-width: 767px) {
            font-size: 16px;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: 'Barlow Condensed', sans-serif;
        font-weight: 500;
        line-height: 1.2;
    }

    h1 {
        font-size: 4.278em;
        margin-bottom: 3.444rem;
        @media (max-width: 857px) {
          font-size: 3.4em;
      }
        @media (max-width: 767px) {
            line-height: 1;
        }
    }

    h2 {
        font-size: 2.778em;
        font-family: 'Lato', sans-serif;
        margin-bottom: 1.944rem;
        font-weight: 500;
    }

    h3 {
        font-family: 'Lato', sans-serif;
        font-size: 2.611em;
        margin-bottom: 1.944rem;
        font-weight: 500;
    }

    h4 {
        font-size: 1.222em;
        font-family: 'Lato', sans-serif;
        color: #F58520;
        font-weight: 500;
    }
    .homeBlock {
        h1 p {
            font-family: 'Barlow Condensed', sans-serif;
            font-weight: inherit;
            line-height: inherit;
        }

        h1 p {
            font-size: inherit;
            @media (max-width: 767px) {
                line-height: inherit;
            }
            span.teximate-word {
                margin-right: 16px !important;
                @media (max-width: 767px) {
                    margin-right: 8px !important;
                }
            }
        }
        h3 {
            font-family: 'Barlow Condensed', sans-serif;
            font-weight: 500;
            line-height: 1.2;
            font-size: 4.278em;
            margin-bottom: 3.444rem;
            @media (max-width: 767px) {
                line-height: 1;
                margin-bottom: 1.444rem;
                font-size: 3.50em;

            }
            @media (max-width: 480px) {
                margin-top: -70px;
            }
            p {
                font-family: 'Barlow Condensed', sans-serif;
                font-weight: inherit;
                line-height: 1;
                font-size: inherit;
                margin-bottom: inherit;
                @media (max-width: 767px) {
                    line-height: inherit;
                }
                span.teximate-word {
                    margin-right: 16px !important;
                    @media (max-width: 767px) {
                        margin-right: 8px !important;
                    }
                }
            }
        }
    }
}

.menuWrap {

    .mat-icon {
        color: #fff;
        font-size: 3em;
        width: 100%;
    }

    .mat-icon-button {
        width: 50px;
        text-align: center;
        height: 50px;

         .mat-button-wrapper {
            display: block;
        }
    }
}

.mat-menu-panel {
    background: #003152;
    border-radius: 0 !important;
    margin-right: 7px;
    margin-top: -11px;

    .mat-menu-content {
        padding: 0 !important;

        .mat-menu-item {
            color: #fff;
            font-family: 'Lato', sans-serif;
            font-size: 1em;
            padding-left: 1.5em;
            padding-right: 1.5em;
            &:hover{
                background-color: #004068;
            }
            @media (max-width: 767px) {
                font-size: 1.25em;
            }
        }
    }
}

.typeContent {
    p {
        margin-bottom: 30px;
        strong {
            display: inline-block;
            margin-bottom: 8px;
        }
    }
}

.typeColor .socialLinks {
    @media (max-width: 767px) {
        padding: 12px 0;
    }

    li {
        a {
            svg .cls-1{
                @media (max-width: 767px) {
                    fill: #3C3D3E;
                }
            }
        }
    }
}
.typeDetails .socialLinks, .resultClass .socialLinks {
    li {
        a {
            svg .cls-1{
                fill: #C8C9C7;
            }
        }
    }
}

.socialLinks {
    position: fixed;
    bottom: 2em;
    z-index: 99;
    min-width: 25px;
    right: 3em;

    @media (max-width: 767px) {
        width: 100%;
        right: 0;
        bottom: 0;
        text-align: center;
        padding: 25px 0;
    }

    li {
        max-width: 35px;
        list-style: none;
        margin: 15px 0;
        transition: 0.3s ease;
        cursor: pointer;

        @media (max-width: 767px) {
            margin: 0 15px;
            display: inline-block;
            vertical-align: middle;
        }

        &:hover {
            transform: scale(1.3);
        }

        a {
            display: block;

            svg {
                width: 25px;
                @media (max-width: 767px) {
                    width: 35px;
                }
            }
        }
    }
}

.aboutpage, .typeDetails, .resultClass {
    .socialLinks {
        @media (max-width: 767px) {
            display: none;
        }
    }
}
.resultClass {
    .home_stars {
        display: none;
    }
}

.typeContent {
    .socialLinks {
        position: static;
        ul {
            li {
                max-width: 35px;
                list-style: none;
                margin: 15px 0;
                display: inline-block;
                vertical-align: middle;
                margin-right: 15px;

                a {
                    display: block;
                }

                svg {
                    width: 35px;
                }
            }
        }
    }
}

.type-details {
    .resultShare {
        .socialLinks li {
            max-width: 35px;
            list-style: none;
            transition: 0.3s ease;
            cursor: pointer;
            display: inline-block;
            vertical-align: middle;
            margin: 10px 6px;
            font-size: 0;

            @media(max-width: 390px) {
               margin: 10px 3px;
            }

            svg {
                width: 30px;
                @media(max-width: 390px) {
                    width: 22px;
                }
            }
        }
    }
}
